<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_routeSlipDetail" />
      <!--路線伝票明細画面-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="search-row-exeBtn"></div>
            <!-- 取引先-->
            <span class="item-spacer">&nbsp;</span>
            <div class="date-style" style="float: left; width: 450px">
              <v-text-field
                dense
                v-model="supplierNm"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <!-- 出荷予定日From-->
            <div class="date-style">
              <v-text-field
                dense
                v-model="shippingScheduleDateFrom"
                :label="$t('label.lbl_scheduleDate') + '（From）'"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日To-->
            <div class="date-style">
              <v-text-field
                dense
                v-model="shippingScheduleDateTo"
                :label="$t('label.lbl_scheduleDate') + '（To）'"
                class="txt-single"
                persistent-hint
                :readonly="true"
              >
              </v-text-field>
            </div>
            <!-- カレンダー終わり -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 路線会社 -->
            <div class="date-style" style="float: left; width: 450px">
              <v-text-field
                dense
                v-model="truckNm"
                :label="$t('label.lbl_routeslipcompany')"
                class="txt-single"
                :readonly="true"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- 発行条件-->
            <div class="date-style" style="float: left; width: 200px">
              <v-text-field
                dense
                v-model="taglabel"
                :label="$t('label.lbl_issueTerms')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="date-style" style="float: left; width: 450px">
              <v-text-field
                dense
                v-model="shippingNm"
                :label="$t('label.lbl_shippingTo')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷日-->
            <div class="search-autocomplete" style="float: left; width: 200px">
              <v-text-field
                dense
                v-model="shippingScheduleDateJ"
                :label="$t('label.lbl_scheduleDate')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="backTo">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <div style="float: right">
                <!-- 納品書発行ボタン -->
                <!-- <v-btn color="primary" class="api-btn" v-on:click="issuanceOfInvoice">
                  {{ $t("btn.btn_issuanceOfInvoice") }}
                </v-btn> -->
                <!-- CSV抽出 -->
                <v-btn color="primary" class="api-btn" @click="routeCsv">{{
                  $t("btn.btn_routeCsv")
                }}</v-btn>
                <!-- 路線伝票発行ボタン -->
                <!-- <v-btn color="primary" class="api-btn" @click="routeSlipIssue">{{
                  $t("btn.btn_routeSlipIssue")
                }}</v-btn> -->
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <!--詳細画面では該当するAPIが存在しないため暫定的に「ページング数」項目名を非表示にする-->
                  <!-- <div>{{ $t("label.lbl_itemsPerPage") }}</div> -->
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--詳細画面では該当するAPIが存在しないため暫定的にページングを非表示にする-->
                <!--表示件数切替ボタン-->
                <!-- <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div> -->
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          ref="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- 空白 -->
          <span class="item-spacer" style="float: left">&nbsp;</span>
          <!-- 出荷No -->
          <template v-slot:[`item.shippingNo`]="{ item }">
            <div class="align-left">{{ item.shippingNo }}</div>
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.itemCdName`]="{ item }">
            <div class="align-left">{{ item.itemCd }}</div>
            <div class="align-left">{{ item.incidental }}</div>
            <div class="align-left">{{ item.itemName }}</div>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lotNo`]="{ item }">
            <div class="align-left">{{ item.lotNo }}<br />{{ item.serial }}</div>
          </template>

          <!-- 数量 -->
          <template v-slot:[`item.quantity`]="{ item }">
            <div class="align-left">{{ item.quantity }}</div>
          </template>
        </v-data-table>
        <!--詳細画面では該当するAPIが存在しないため暫定的にページャーを非表示にする-->
        <!-- ページネーション -->
        <!-- <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div> -->
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <!-- 詳細設定モーダル -->
      <v-dialog v-model="isOpenEditDetailDialog" :max-width="1300" persistent>
        <editDetailDateDialog
          :editDetailDateDialog.sync="isOpenEditDetailDialog"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
export default {
  name: appConfig.SCREEN_ID.P_ROU_001,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 輸送会社データ項目
    truckInfo: { truck: "" },
    // 出荷予定日From
    shippingScheduleDateFrom: "",
    // 出荷予定日To
    shippingScheduleDateTo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷日
    shippingScheduleDateJ: "",
    // 発行条件
    taglabel: "",
    // 取引先(text)
    supplierNm: "",
    // 出荷先
    shippingSelected: "",
    shippingNm: "",
    outListSid: "",
    carrierCompCd7: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // 選択行
    selectedIndex: 0,
    // メニュー
    openMenu: null,
    isOpenEditDetailDialog: false,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // 荷札発行枚数ダイアログ
    numberIssuesDialog: false,
    // 荷札発行枚数
    numIssue: "",
    // 取引先エラーメッセージ
    alertSupplierMessage: "",
    //帳票テンプレート選択値
    invoiceTemplateSelected: null,
    //帳票テンプレートリスト
    invoiceTemplateList: [
      { value: "", text: "", name: "" },
      { text: "SA001", value: "SA001", name: "SA001" },
      { text: "SA002", value: "SA002", name: "SA002" },
    ],
    isRouteSearch: false,
    // 路線伝票画面に受け渡しパラメータ
    routeSlipDetailConfig: {},
    // ヘッダ
    headerItems: [
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shippingNo",
        width: "7%",
        align: "left",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCdName",
        width: "7%",
        align: "left",
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "7%",
        align: "left",
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "7%",
        align: "center",
      },
    ],
    // CSV出力URL
    returnURL: "",
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    //ページに表示する一覧
    // showItemsPerPage: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 発行条件プルダウン
    issueTermsList: [],
    // 路線会社名（画面表示用）
    truckNm: "",
    truckSelected: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 荷札発行権限
    tagExportRole: "true",

    // 画面表示用
    shippingNo: "",
    itemCd: "",
    lot: "",
    itemNm: "",
    quantity: "",

    // 入力チェック
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),
  created() {},

  methods: {
    //  テーブルフッターの高さ変更
    append() {
      this.truckKbn = "";
    },
    link() {
      location.href = this.returnURL;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    // 戻るボタン押下
    backTo() {
      // 路線伝票画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_005,
        params: {
          routeSlipDetailData: this.routeSlipDetailConfig,
        },
      });
    },
    /**
     * 初期値
     */
    init() {
      // リクエストパラメータを設定
      this.inputList = [];
      this.deleteList = [];
      this.suppliersSelected = "";
      let routeSlipDetailConfig = this.$route.params.routeSlipDetailData;
      this.routeSlipDetailConfig = this.$route.params.routeSlipDetailData;
      this.supplierNm = routeSlipDetailConfig.supplierNm;
      this.shippingNm = routeSlipDetailConfig.shippingNm;
      this.taglabel = routeSlipDetailConfig.taglabel;
      this.suppliersSelected = routeSlipDetailConfig.searchSuppliersSelected;
      this.shippingSelected = routeSlipDetailConfig.shippingSelected;
      this.shippingScheduleDateFrom = routeSlipDetailConfig.searchShippingScheduleDateFrom;
      this.shippingScheduleDateTo = routeSlipDetailConfig.searchShippingScheduleDateTo;
      this.shippingScheduleDateJ = routeSlipDetailConfig.shippingScheduleDateJ;
      let dtlList = routeSlipDetailConfig.dtlList;
      this.truckNm = routeSlipDetailConfig.truckNm;
      this.truckSelected = routeSlipDetailConfig.searchDeliveryTraderSid;
      this.outListSid = routeSlipDetailConfig.outListSid;
      // 明細画面のテーブルの行数から総件数を取得
      this.totalCount = dtlList.length;
      this.carrierCompCd7 = routeSlipDetailConfig.carrierCompCd7;
      this.invoiceTemplateSelected = routeSlipDetailConfig.invoiceTemplateSelected;

      if (dtlList != null) {
        for (var i = 0; i < dtlList.length; i++) {
          let item = {
            shippingNo: routeSlipDetailConfig.shippingNo,
            itemCdName: "${dtlList[i].itemCd}" + "${dtlList[i].itemName}",
            lotNo: dtlList[i].lotNo.substring(0, 20).trimRight(),
            serial: dtlList[i].lotNo.slice(-80).trimRight(),
            itemCd: dtlList[i].itemCd.substring(0, 50).trim(),
            itemName: dtlList[i].itemName,
            incidental: dtlList[i].itemCd.slice(-50).trim(),
            quantity: dtlList[i].shipScheduleQuantity,
          };
          this.inputList.push(item);
        }
      } else {
        this.loadingCounter = 0;
      }
      //詳細画面で該当するAPIが存在しないため暫定的にページング処理を非表示にする
      // // ソート
      // if (this.sortItem != "") {
      //   this.inputList = this.sortChange(this.inputList, this.ascdesc, this.sortBy);
      // }
      // // ページング処理
      // this.showItemsPerPage = this.inputList;
      // this.inputList = [];
      // // 該当ページ表示件数設定
      // if (this.itemsPerPage == "100") {
      //   this.pageCount = Math.ceil(this.totalCount / 100);
      // } else if (this.itemsPerPage == "50") {
      //   this.pageCount = Math.ceil(this.totalCount / 50);
      // } else {
      //   this.pageCount = Math.ceil(this.totalCount / 10);
      // }
      // // 現ページ最初行、最後行取得
      // const first = (this.page - 1) * this.itemsPerPage + 1;
      // const last = this.page * this.itemsPerPage;
      // // 現ページ全行取得設定
      // this.showItemsPerPage.forEach((item, i) => {
      //   if (i < first - 1 || i > last - 1) return;
      //   this.inputList.push(item);
      // });

      // 編集不可
      this.editing = false;
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 詳細ダイアログを表示
     */
    editdetail() {
      this.isOpenEditDetailDateDialog = true;
    },
    /**
     * 明細画面：取引先コードリストボックス取得処理
     */
    // TODO 路線伝票フォーマットAPI呼び出し
    setInvoiceTemplate() {
      for (var i = 0; i < this.invoiceTemplateList.length; i++) {
        if (this.invoiceTemplateList[i].value == this.invoiceTemplateSelected) {
          return this.invoiceTemplateList[i].name;
        }
      }
    },
    /**
     * 納品書発行
    //  */
    // issuanceOfInvoice() {
    //   // ローディング画面表示ON
    //   this.loadingCounter = 1;
    //   // APIパラメータ設定
    //   const body = this.$httpClient.createGetApiRequestConfig();
    //   // 画面ID
    //   body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_007;
    //   // 取引先
    //   body.reqIdv.clientSid = this.suppliersSelected;

    //   // console.debug("issuanceOfInvoice() Body", body);

    //   return new Promise((resolve, reject) => {
    //     this.$httpClient
    //       // 接続API_URL入力
    //       .secureGet(appConfig.API_URL.ISSUANCE_OF_INVOICE, body, appConfig.APP_CONFIG)
    //       .then((response) => {
    //         // console.debug("issuanceOfInvoice() Respone", response);
    //         const jsonData = JSON.parse(JSON.stringify(response.data));

    //         // 正常時
    //         if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
    //           this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
    //             i18n.tc("btn.btn_send"),
    //           ]);
    //           this.infoDialog.title = "結果";
    //           this.infoDialog.isOpen = true;
    //           this.infoDialog.screenFlag = true;
    //           this.infoDialog.firstPageFlag = true;
    //           resolve(response);
    //           // リストクリア
    //           this.inputList = [];
    //         } else {
    //           // エラー時
    //           this.infoDialog.message = jsonData.resCom.resComMessage;
    //           this.infoDialog.title = appConfig.DIALOG.title;
    //           this.infoDialog.isOpen = true;
    //           this.infoDialog.screenFlag = true;
    //           this.infoDialog.firstPageFlag = false;
    //           reject();
    //         }
    //       })
    //       .catch((ex) => {
    //         this.infoDialog.message = ex;
    //         this.infoDialog.title = appConfig.DIALOG.title;
    //         this.infoDialog.isOpen = true;
    //         this.infoDialog.screenFlag = true;
    //         this.infoDialog.firstPageFlag = false;
    //         reject();
    //       })
    //       .finally(() => {
    //         // ローディング画面表示OFF
    //         this.loadingCounter = 0;
    //       });
    //   });
    // if (this.$refs.form.validate()) {
    //   // console.debug("Issuance Of Invoice");
    //   // TODO 納品書発行API呼び出し
    //   return;
    // }
    // },
    /**
     * 路線CSV出力
     */
    routeCsv() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createRequestBodyConfig();
      // 出荷先Sid
      // 路線会社Sid
      const noList = [];
      var list = {};
      list.outListSid = this.outListSid;
      list.deliveryTraderSid = this.truckSelected;
      list.carrierCompCd7 = this.carrierCompCd7;
      list.formatNo = this.invoiceTemplateSelected;
      noList.push(list);

      config.reqIdv.routeSlip = noList;
      // 画面ID
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_007;

      // console.debug("routeCsv() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.ROUTE_SLIP_CSV, config, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("routeCsv() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              console.log("sccess");
              let filePathList = jsonData.resIdv.filePath;
              if (filePathList != null) {
                this.returnURL = filePathList[0];
              }
              this.link();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 路線伝票発行
     */
    // routeSlipIssue() {
    //   // ローディング画面表示ON
    //   this.loadingCounter = 1;
    //   // APIパラメータ設定
    //   const body = this.$httpClient.createRequestBodyConfig();

    //   body.reqIdv.clientSid = this.supplie; // 取引先

    //   // console.debug("routeSlipIssue() Body", body);

    //   return new Promise((resolve, reject) => {
    //     this.$httpClient
    //       // 接続API
    //       .secureGet(appConfig.API_URL.ROUTE_SLIP_ISSUE, body, appConfig.APP_CONFIG)
    //       .then((response) => {
    //         // console.debug("routeSlipIssue() Response", response);
    //         const jsonData = JSON.parse(JSON.stringify(response.data));

    //         // 正常時
    //         if ((jsonData.resCom.resComCode = appConfig.RESCOMCODE_SUCCESS)) {
    //           this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
    //             i18n.tc("btn.btn_send"),
    //           ]);
    //           this.infoDialog.title = "結果";
    //           this.infoDialog.isOpen = true;
    //           this.infoDialog.screenFlag = true;
    //           resolve(response);
    //           // リストクリア
    //           this.inputList = [];
    //         } else {
    //           // エラー時
    //           this.infoDialog.message = jsonData.resCom.resComMessage;
    //           this.infoDialog.title = appConfig.DIALOG.title;
    //           this.infoDialog.isOpen = true;
    //           reject();
    //         }
    //       })
    //       .catch((ex) => {
    //         this.infoDialog.message = ex;
    //         this.infoDialog.title = appConfig.DIALOG.title;
    //         this.infoDialog.isOpen = true;
    //         this.infoDialog.screenFlag = true;
    //         reject();
    //       });
    // });
    // if (this.$refs.form.validate()) {
    //   // console.debug("Route Csv");
    //   // TODO 路線伝票発行API呼び出し
    //   return;
    // }
    // },
    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected() {
      this.alertSupplierMessage = "";
    },

    shippingScheduleDateFromCal(val) {
      this.shippingScheduleDateFrom = this.formatDate(val);
    },
    shippingScheduleDateToCal(val) {
      this.shippingScheduleDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SHP_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
</style>
